import React from 'react';
import ProjectOne from "../../elements/project/projectOne";


const Project = () => {
    return (
        <div className="rn-portfolio-area pt--200 pb--150 bg-color-grey portfolio-style-1">
            <div className="container">
                <div className="row mb--10">
                    <div className="col-lg-12">
                        <div className="section-title">
                            <h3 className="title"> 
                                Featured works
                                <span className="bg">Portfolio</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <ProjectOne />
            </div>
        </div>
    )
}
export default Project
