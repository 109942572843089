import React from 'react'
import {useStaticQuery, graphql} from 'gatsby';
import { Controller, Scene } from 'react-scrollmagic';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const About = ( ) => {
    const aboutQueryData = useStaticQuery(graphql`
        query AboutDefaultQuery {
          directus {
            homepage {
              name
              role
              about_me_image {
                imageFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                id
              }
              link
              about_me
            }
          }  
        }
    `);

    const title = aboutQueryData.directus.homepage.name;
    const Subtitle = aboutQueryData.directus.homepage.role;
    const description = aboutQueryData.directus.homepage.about_me;
    const linkBUtton = aboutQueryData.directus.homepage.link;
    const PortfolioImages = getImage(aboutQueryData.directus.homepage.about_me_image.imageFile.childImageSharp.gatsbyImageData)


    return (
        <div className="rb-about-area about-style rn-section-gap bg-color-white" id="about">
            <div className="container">
                <div className="row row--45 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <div className="trigger" id="trigger2" />
                            <Controller>
                                <Scene classToggle="animated" triggerElement="#trigger2" triggerHook="onCenter">
                                    <div className="rn_surface story-image">
                                      <GatsbyImage className="about-images" image={PortfolioImages} alt="Amira Kopeyeva photo" />
                                    </div>
                                </Scene>
                            </Controller>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="inner">
                            <div className="content">
                                <div className="section-title">
                                    <div className="title-wrap">
                                        <h3 className="title wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">{title}<span className="bg">About</span></h3>
                                        {title && <h4 className="subtitle wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms" dangerouslySetInnerHTML={{ __html: Subtitle }}></h4>}
                                    </div>
                                    
                                    {description && <span className="description wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms" dangerouslySetInnerHTML={{ __html: description }}></span>}
                                </div>
                                <div className="button-group mt--30">
                                    <a className="rn-button wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms" href={linkBUtton}><span>imdb page</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About