import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import { Controller, Scene } from 'react-scrollmagic';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Banner = () => {
    const banenrQueryData = useStaticQuery (graphql`
        query BannerDefaultQuery {
          directus {
            homepage {
              name
              role
              welcome_image {
                imageFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                id
              }
            }
          }
        }
    `);
      
    // const BannerImages = banenrQueryData.homedefaultJson.bgImage.childImageSharp.fluid;
    const PortfolioImages = getImage(banenrQueryData.directus.homepage.welcome_image.imageFile.childImageSharp.gatsbyImageData)
    const Title = banenrQueryData.directus.homepage.name.split(" ");
    const SubTitle = banenrQueryData.directus.homepage.role;
    const Name = Title[0].toString()
    const Surname = Title[1].toString()

    return (
        <div className="rn-slider-area" id="home">
            {/* Start Single Slider  */}
            <div className="rn-slide slider-style-01 banner-fixed-height">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-xl-6">
                            <div className="inner">
                                <div className="content text-left">
                                    <h1 className="title wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">{Name}<span className='color-primary'>{Surname}</span></h1>
                                    <h4 className="subtitle wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms" dangerouslySetInnerHTML={{ __html: SubTitle }}></h4>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            {/* End Single Slider  */}
            <div className="thumbnail">
                <div className="trigger" id="trigger" />
                <Controller>
                    <Scene classToggle="animated" triggerElement="#trigger" triggerHook="onCenter">
                        <div className="thumbnail-inner rn_surface story-image">
                          <GatsbyImage className="portfolio-images" image={PortfolioImages} alt="Amira Kopeyeva photo" />
                        </div>
                    </Scene>
                </Controller>
            </div>
          
           
        </div>
    )
}
export default Banner;