import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Projectcard from "./projectcard";


const ProjectOne = () => {
    const ProjectData = useStaticQuery(graphql`
        query ProjectDataQuery {
            directus {
              portfolio {
                title
                slug
                sort
                youtube_code
                role
                id
                content
                featured_image {
                  imageFile {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                        presentationHeight
                      }
                    }
                  }
                  id
                }
              }
            }
          }
    `);

    var projectsData = ProjectData.directus.portfolio;
    if(projectsData){
      projectsData = projectsData.sort(function(a, b) {
        var keyA = a.sort,
          keyB = b.sort;
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
    }
    return (
        <div className="row row--45 mt_dec--30">
            {projectsData.map( data => (
                <Projectcard
                    column="col-lg-4 col-md-6 col-12"
                    portfolioStyle="portfolio-style-1"
                    id={data.slug} 
                    key={data.id} 
                    image={data.featured_image.imageFile.childImageSharp}
                    title={data.title}
                    role={data.role}
                    link={data.youtube_code}
                />
            ))}
        </div>
    )
}

export default ProjectOne;