import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Banner from "../components/homedefault/banner";
import About from "../components/homedefault/about";
import Project from "../components/homedefault/project";


const IndexPage = () => (
  <Layout>
    <Seo title="Amira Kopeyeva" />
    <Banner />
    <About />
    <div className="portfolio-id" id="portfolio">
      <Project />
    </div>
  </Layout>
)
export default IndexPage;